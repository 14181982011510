import { Search } from '@mui/icons-material';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { CanAccess } from 'core/components';
import StyledDataGrid from 'core/components/DataGrid';
import { useAPI, usePermissions } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import { dayjsFromObject } from 'core/services/intl';
import { openToast } from 'core/services/toast';
import { Actions } from 'core/types/permissions';
import { useFormik } from 'formik';
import CarriersService from 'modules/irp/modules/supplements/api/CarriersService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import AgencyUseOnlyDivider from 'modules/irp/modules/supplements/components//AgencyUseOnlyDivider';
import SupplementApproveAndInvoice from 'modules/irp/modules/supplements/components/SupplementApproveAndInvoice';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import TermsCheckbox from 'modules/irp/modules/supplements/components/TermsCheckbox';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import Vehicle from 'modules/irp/modules/vehicles/types/Vehicle';
import RazorPaths from 'modules/razor/paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Address, AddressType, PhysicalDeliveryType } from 'types/Address';
import { CarrierContact } from 'types/Carrier';
import ElectronicDeliveryMethod from 'types/ElectronicDeliveryMethod';
import Permissions from 'types/Permissions';
import Program from 'types/Program';
import { SupplementActionFields, SupplementStatus, SupplementSubmitFormFields } from 'types/Supplement';
import * as Yup from 'yup';

export interface SupplementSubmitFormProps<T extends GridValidRowModel> {
	previousPath: string;
	columns: GridColDef<T>[];
	getRows?: () => Promise<T[]>;
	filterRows?: (searchValue: string, rows: T[]) => T[];
	noCredentials?: true;
	showEffectiveMonth?: boolean;
	termsAgreement?: boolean;
}

export default function SupplementSubmitForm<T extends GridValidRowModel = Vehicle>({
	previousPath,
	columns,
	getRows,
	filterRows,
	noCredentials,
	showEffectiveMonth,
	termsAgreement,
}: SupplementSubmitFormProps<T>) {
	// Hooks
	const { t } = useTranslation(['irp/supplements', 'irp/vehicles']);
	const navigate = useNavigate();
	const { supplement } = useSupplement();
	const { canAccess } = usePermissions();
	const { clientAddresses } = useClient();
	const { carrier } = useCarrier();
	const { accountKey } = useRazor();

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [rows, setRows] = useState<T[]>([]);
	const [preferredContact, setPreferredContact] = useState<CarrierContact | null>(null);
	const [searchValue, setSearchValue] = useState<string>('');

	// Services
	const supplementsService = useAPI(SupplementsService);
	const carriersService = useAPI(CarriersService);

	const validationSchema = Yup.object().shape({
		submitted: Yup.boolean().required(),
		approved: Yup.boolean(),
		termsCertified: Yup.boolean().isTrue(t('data.validation.form_incomplete', { ns: 'core' })),
	});

	// Form
	const formik = useFormik<SupplementSubmitFormFields>({
		initialValues: { approved: false, termsCertified: !termsAgreement, submitted: true },
		validationSchema,
		onSubmit: (updateFields) => {
			if (!supplement || !accountKey) return undefined;

			const { approved, submitted } = updateFields;
			const finalData: SupplementActionFields = { approved, submitted };

			return supplementsService.triggerAction(supplement.key, finalData).then(({ supplement: updatedSupplement }) => {
				// CLEAR-2038: Redirect to payment page if supplement status is invoiced,
				//             otherwise redirect to manage supplements
				const redir =
					updatedSupplement.status.code === SupplementStatus.Invoiced
						? RazorPaths.Payments.buildPath({})
						: RazorPaths.Manage.Supplements.buildPath(
								{},
								{ accountKeyId: supplement.accountKey, supplementKeyId: supplement.key },
							);
				navigate(redir, { state: { bypass: true } });

				// Return new promise to keep the loading state on the Submit button
				return new Promise(() => {
					/* do nothing */
				});
			});
		},
	});

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();

		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'new-account/supplement-submit-form',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	// Computed
	const DataGrid = StyledDataGrid<T>();

	// Set address based on credentials delivery method
	const address: Address | null = (() => {
		switch (supplement.physicalDelivery?.method?.code) {
			case PhysicalDeliveryType.MailingAddress:
				// Default to physical if fleet mailing is not set
				return (
					supplement?.fleet.addresses.mailing ||
					carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) ||
					null
				);
			case PhysicalDeliveryType.BusinessAddress:
				return carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) || null;
			case PhysicalDeliveryType.PickupAddress:
				return clientAddresses ? clientAddresses[0] : null;
			case PhysicalDeliveryType.OtherAddress:
				return supplement?.physicalDelivery?.address || null;
			default:
				return null;
		}
	})();

	// Filter rows if provided
	const filteredRows = filterRows ? filterRows(searchValue, rows) : defaultFilterRows(searchValue, rows);

	useEffect(() => {
		if (!supplement) return;

		const defaultGetRows = async () => {
			if (!supplement) return [];
			const vehicles = supplementsService.listVehicles(supplement.key, { weightGroup: true });
			return vehicles as unknown as T[];
		};

		Promise.all([
			(getRows || defaultGetRows)().then(setRows),
			carriersService.getContact(supplement.accountKey, Program.IRP).then(setPreferredContact),
		]).finally(() => setLoading(false));
	}, [supplementsService, supplement, carriersService, getRows]);

	if (loading) return SupplementContentSkeleton;

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Box>
						<Typography variant="h3" gutterBottom>
							{t('submit.title')}
						</Typography>
						<Typography>{t('submit.subtitle')}</Typography>
					</Box>

					<Box display="flex" flexDirection="column" mt={3} gap={2}>
						{showEffectiveMonth && (
							<Grid container>
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{t('supplement.effectiveMonth', { ns: 'data' })}
									</Typography>
									<Typography variant="body2">
										{dayjsFromObject(supplement?.effectiveDate)?.format('MMMM YYYY')}
									</Typography>
								</Grid>
							</Grid>
						)}

						<Grid container>
							<Grid item xs={12} sm={3}>
								<Typography variant="subtitle2" gutterBottom>
									{t('supplement.invoiceDelivery.method.label', { ns: 'data' })}
								</Typography>
								<Typography variant="body2">{supplement?.invoiceDelivery?.method?.displayName}</Typography>
							</Grid>
							{supplement?.invoiceDelivery?.method?.code !== ElectronicDeliveryMethod.DownloadPdf && (
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{supplement?.invoiceDelivery?.method?.displayName}
									</Typography>
									<Typography variant="body2">{supplement?.invoiceDelivery?.details}</Typography>
								</Grid>
							)}
						</Grid>

						{!noCredentials && (
							<Grid container>
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{t('supplement.physicalDelivery.method.label', { ns: 'data' })}
									</Typography>
									<Typography variant="body2">{supplement?.physicalDelivery?.method?.displayName}</Typography>
								</Grid>
								{supplement?.physicalDelivery?.method?.code !== ElectronicDeliveryMethod.Email && (
									<Grid item xs={12} sm={3}>
										<Typography variant="subtitle2" gutterBottom>
											{t('data.fields.address', { ns: 'core' })}
										</Typography>
										{address && (
											<>
												<Typography variant="body2">{address.line1}</Typography>
												<Typography variant="body2">{address.line2}</Typography>
												<Typography variant="body2">
													{address.city}, {address.state?.code} {address.postalCode}, {address.country}
												</Typography>
											</>
										)}
									</Grid>
								)}
								{supplement?.physicalDelivery?.method?.code === ElectronicDeliveryMethod.Email && (
									<Grid item xs={12} sm={3}>
										<Typography variant="subtitle2" gutterBottom>
											{t('data.fields.email', { ns: 'core' })}
										</Typography>
										{preferredContact && <Typography variant="body2">{preferredContact.contact.email}</Typography>}
									</Grid>
								)}
							</Grid>
						)}

						<CanAccess resource={Permissions.IRP.Supplements.Fields.InvoiceComments.resource} action={Actions.READ}>
							<Grid container columnGap={8}>
								<Grid item xs={12} sm={6}>
									<Typography variant="subtitle2" gutterBottom>
										{t('supplement.invoice_comments', { ns: 'data' })}
									</Typography>
									<Typography variant="body2">{supplement?.invoiceComments || <span>&mdash;</span>}</Typography>
								</Grid>
							</Grid>
						</CanAccess>
					</Box>

					<Box display="flex" flexDirection="row" justifyContent="space-between" my={4}>
						<Typography variant="h5">{t('details.title', { ns: 'irp/vehicles' })}</Typography>
						<Input
							startAdornment={<Search color="primary" />}
							placeholder={`${t('buttons.search', { ns: 'core' })}...`}
							value={searchValue}
							onChange={(e) => setSearchValue(e.currentTarget.value)}
						/>
					</Box>

					<DataGrid className="striped" columns={columns} rows={filteredRows} disableRowSelectionOnClick />

					{termsAgreement && (
						<TermsCheckbox formik={formik} verbiage={t('submit.terms.verbiage', { ns: 'irp/supplements' })} />
					)}

					<Box mt={2}>
						<AgencyUseOnlyDivider />
						<SupplementApproveAndInvoice
							value={formik.values.approved || false}
							onChange={(v) => formik.setFieldValue('approved', v)}
						/>
					</Box>
				</CardContent>
			</Card>
			<SupplementStepFooter
				nextLabel={t('buttons.submit', { ns: 'core' })}
				onNext={handleNext}
				nextDisabled={!canAccess(Permissions.IRP.Supplements.resource, Actions.WRITE)}
				previousPath={previousPath}
			/>
		</Box>
	);
}

function defaultFilterRows<T extends GridValidRowModel = Vehicle>(searchValue: string, rows: T[]) {
	return rows.filter((vehicle) => {
		const { vin, title, unitNumber } = vehicle;
		const search = searchValue.toLowerCase();
		return (
			vin.toLowerCase().includes(search) ||
			title?.number?.toLowerCase().includes(search) ||
			unitNumber.toLowerCase().includes(search)
		);
	});
}
