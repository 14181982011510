import { Card, CardContent, Grid, InputLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CanAccess } from 'core/components';
import ClearFleetForm, { ComponentField, Field, SelectField, TextField } from 'core/components/ClearFleetForm';
import FormInputField from 'core/components/FormInputField';
import { useAPI, usePermissions, useToast } from 'core/hooks';
import { dayjsFromObject, phoneNumberFormat } from 'core/services/intl';
import { Actions } from 'core/types/permissions';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import CarriersService from 'modules/irp/modules/supplements/api/CarriersService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Address, AddressType, AddressValidationSchema, PhysicalDeliveryType } from 'types/Address';
import { CarrierContact } from 'types/Carrier';
import Date, { DateValidationSchema } from 'types/Date';
import ElectronicDeliveryMethod from 'types/ElectronicDeliveryMethod';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import Permissions from 'types/Permissions';
import Program from 'types/Program';
import { State, stateSelectOptions, statesWithDivider } from 'types/State';
import * as Yup from 'yup';
import SupplementFeesDataGrid, { SupplementFeesDataGridProps } from './SupplementFeesDataGrid';
import { SupplementContentSkeleton } from './SupplementPageContainer';

export interface SupplementVerifyFormFields {
	effectiveDate?: Date | null;
	invoiceComments?: string;
	invoiceDelivery: {
		method: LookupValue | null;
		details?: string;
	};
	physicalDelivery: {
		method: LookupValue | null;
		details?: string;
		address: Address | null;
	};
}

export interface SupplementVerifyFormProps {
	previousPath: { buildPath: (params: { supplementKey: string }) => string };
	nextPath: { buildPath: (params: { supplementKey: string }) => string };
	feeGridHeaders?: SupplementFeesDataGridProps['columnHeaders'];
	showEffectiveMonth?: true;
	noCredentials?: true;
}

export default function SupplementVerifyForm({
	previousPath,
	nextPath,
	feeGridHeaders,
	showEffectiveMonth,
	noCredentials,
}: SupplementVerifyFormProps) {
	// Hooks
	const { t } = useTranslation('irp/supplements');
	const supplementsService = useAPI(SupplementsService);
	const carriersService = useAPI(CarriersService);
	const { openToast } = useToast();
	const { supplement, reload: reloadSupplement } = useSupplement();
	const { canAccess } = usePermissions();
	const { states, clientAddresses } = useClient();
	const { carrier } = useCarrier();
	const navigate = useNavigate();
	const { supplementKey } = useTypedParams(SupplementPaths.Supplement);

	// State
	const [loading, setLoading] = useState(true);
	const [electronicDeliveryMethods, setElectronicDeliveryMethods] = useState<LookupValue[]>([]);
	const [credentialDeliveryMethods, setCredentialDeliveryMethods] = useState<LookupValue[]>([]);
	const [preferredContact, setPreferredContact] = useState<CarrierContact | null>(null);

	// Validation schema
	const physicalDeliverySchema = Yup.object({
		method: Yup.object<LookupValue>()
			.shape(LookupValueValidationSchema)
			.required(t('data.validation.required', { ns: 'core' })),
		address: Yup.object<Address>().when('method', ([method], s) => {
			if (method?.code === PhysicalDeliveryType.OtherAddress)
				return s.shape(AddressValidationSchema({ t, optionalCounty: true }));
			return s.optional().strip();
		}),
	});
	const schema = Yup.object().shape({
		effectiveDate: showEffectiveMonth
			? DateValidationSchema.required(t('data.validation.required', { ns: 'core' }))
			: DateValidationSchema.optional().strip(),
		invoiceDelivery: Yup.object({
			method: Yup.object<LookupValue>()
				.shape(LookupValueValidationSchema)
				.required(t('data.validation.required', { ns: 'core' })),
			details: Yup.string().when('method', ([method], s) => {
				if (method?.code === ElectronicDeliveryMethod.Email) {
					return s
						.email(t('data.validation.email', { ns: 'core' }))
						.required(t('data.validation.required', { ns: 'core' }));
				}

				if (method?.code === ElectronicDeliveryMethod.Fax) {
					return s
						.matches(/^\(\d{3}\) \d{3}-\d{4}$/, t('data.validation.fax', { ns: 'core' }))
						.required(t('data.validation.required', { ns: 'core' }));
				}

				return s;
			}),
		}),
		physicalDelivery: noCredentials ? Yup.object().strip() : physicalDeliverySchema,
		invoiceComments: Yup.string().max(255, t('data.validation.len.max', { ns: 'core', len: 255 })),
	});

	// Form
	const formik = useFormik<SupplementVerifyFormFields>({
		initialValues: {
			effectiveDate: supplement?.effectiveDate || null,
			invoiceDelivery: {
				method: supplement?.invoiceDelivery?.method || null,
				details: supplement?.invoiceDelivery?.details || '',
			},
			physicalDelivery: {
				method: supplement?.physicalDelivery?.method || null,
				details: supplement?.physicalDelivery?.details || '',
				address: supplement?.physicalDelivery?.address || {
					line1: '',
					line2: '',
					city: '',
					state: null,
					postalCode: '',
					country: '',
				},
			},
			invoiceComments: supplement?.invoiceComments || '',
		},
		validationSchema: schema,
		onSubmit: (form) => {
			if (!supplement) return undefined;

			const submitFields = form;

			// Can update comments if the user has the permission
			if (!canAccess(Permissions.IRP.Supplements.Fields.InvoiceComments.resource, Actions.UPDATE)) {
				delete submitFields.invoiceComments;
			}

			const updateSupplement = schema.cast(submitFields, {
				context: submitFields,
				// Final cast to strip unknown fields, this is required to satisfy API minimum requirements
				stripUnknown: true,
			}) as SupplementVerifyFormFields;

			return supplementsService.update(supplement.key, updateSupplement).then(() => {
				reloadSupplement();
				navigate(nextPath.buildPath({ supplementKey }));
			});
		},
	});

	// Set address based on credentials delivery method
	const address: Address | null = (() => {
		switch (formik.values.physicalDelivery.method?.code) {
			case PhysicalDeliveryType.MailingAddress:
				// Default to physical if fleet mailing is not set
				return (
					supplement?.fleet.addresses.mailing ||
					carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) ||
					null
				);
			case PhysicalDeliveryType.BusinessAddress:
				return carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) || null;
			case PhysicalDeliveryType.PickupAddress:
				return clientAddresses ? clientAddresses[0] : null;
			case PhysicalDeliveryType.OtherAddress:
				return supplement?.physicalDelivery?.address || null;
			default:
				return null;
		}
	})();

	const EmailField: TextField<SupplementVerifyFormFields> = {
		type: 'text',
		name: 'invoiceDelivery.details',
		label: t('data.fields.email', { ns: 'core' }),
		required: true,
		getValue: (v) => v.invoiceDelivery.details || '',
	};

	const FaxField: TextField<SupplementVerifyFormFields> = {
		type: 'text',
		name: 'invoiceDelivery.details',
		label: t('data.fields.fax', { ns: 'core' }),
		required: true,
		max: 14,
		getValue: (v) => phoneNumberFormat(v.invoiceDelivery.details || ''),
	};

	const addressFields: Field<SupplementVerifyFormFields>[] = [
		{
			type: 'text',
			name: 'physicalDelivery.address.line1',
			label: t('data.fields.street1', { ns: 'core' }),
			max: 255,
			required: true,
			getValue: (v) => v.physicalDelivery.address?.line1 || '',
		},
		{
			type: 'text',
			name: 'physicalDelivery.address.line2',
			label: t('data.fields.street2', { ns: 'core' }),
			max: 255,
			getValue: (v) => v.physicalDelivery.address?.line2 || '',
		},
		{
			type: 'text',
			name: 'physicalDelivery.address.city',
			label: t('data.fields.city', { ns: 'core' }),
			max: 255,
			required: true,
			getValue: (v) => v.physicalDelivery.address?.city || '',
		},
		{
			type: 'select',
			name: 'physicalDelivery.address.state',
			label: t('data.fields.state', { ns: 'core' }),
			required: true,
			options: statesWithDivider(states),
			getValue: (v) => v.physicalDelivery.address?.state || null,
			selectProps: {
				...stateSelectOptions,
			},
		} as SelectField<SupplementVerifyFormFields, State>,
		{
			type: 'text',
			name: 'physicalDelivery.address.postalCode',
			label: t('data.fields.zip', { ns: 'core' }),
			max: 10,
			required: true,
			getValue: (v) => v.physicalDelivery.address?.postalCode || '',
		},
	];

	const addressDisplayField: ComponentField = {
		type: 'component',
		name: 'addressDisplay',
		component:
			address && address.line1 ? (
				<Box>
					<InputLabel sx={{ mb: 1 }}>{t('data.fields.address', { ns: 'core' })}</InputLabel>
					<Typography variant="body2">{address.line1}</Typography>
					<Typography variant="body2">{address.line2}</Typography>
					<Typography variant="body2">
						{address.city}, {address.state?.code} {address.postalCode}, {address.country}
					</Typography>
				</Box>
			) : null,
	};

	const emailDisplayField: ComponentField = {
		type: 'component',
		name: 'emailDisplay',
		component: preferredContact ? (
			<Box>
				<InputLabel sx={{ mb: 1 }}>{t('data.fields.email', { ns: 'core' })}</InputLabel>
				<Typography>{preferredContact.contact.email}</Typography>
			</Box>
		) : null,
	};

	const effectiveMonthFields: Field<SupplementVerifyFormFields>[] = [
		{
			type: 'date',
			name: 'effectiveDate',
			label: t('supplement.effectiveMonth', { ns: 'data' }),
			tooltip: t('verify.effectiveMonth', { ns: 'irp/supplements' }),
			required: true,
			getValue: (v) => {
				const date = dayjsFromObject(v.effectiveDate);
				if (!date) return null;

				date.set('date', 1);
				return v.effectiveDate ? date : null;
			},
			datePickerProps: {
				minDate: supplement?.fleet ? dayjsFromObject(supplement.fleet.startDate) || undefined : undefined,
				maxDate: supplement?.fleet ? dayjsFromObject(supplement.fleet.endDate) || undefined : undefined,
				referenceDate: dayjs().set('date', 1),
				views: ['year', 'month'],
				openTo: 'month',
			},
		},
		{
			type: 'component',
			name: 'effectiveMonthSpacer',
			fullWidth: true,
			gridProps: { paddingTop: '0 !important' },
			component: null,
		},
	];

	const credentialDeliveryFields: Field<SupplementVerifyFormFields>[] = [
		{
			type: 'component',
			name: 'physicalDeliveryMethodSpacer',
			fullWidth: true,
			gridProps: { paddingTop: '0 !important' },
			component: null,
		},
		{
			type: 'select',
			name: 'physicalDelivery.method',
			label: t('supplement.physicalDelivery.method.label', { ns: 'data' }),
			options: credentialDeliveryMethods,
			required: true,
			getValue: (v) => (credentialDeliveryMethods && v.physicalDelivery.method) || null,
			selectProps: {
				getOptionKey: (o) => o.id,
				getOptionLabel: (o) => o.displayName,
			},
		} as SelectField<SupplementVerifyFormFields, LookupValue>,
		...(formik.values.physicalDelivery.method?.code === ElectronicDeliveryMethod.Email ? [emailDisplayField] : []),
		// Address fields, if credentials is set
		...(formik.values.physicalDelivery.method?.code === PhysicalDeliveryType.OtherAddress
			? addressFields
			: [addressDisplayField]),
	];

	const fields: Field<SupplementVerifyFormFields>[] = [
		...(showEffectiveMonth ? effectiveMonthFields : []),
		{
			type: 'select',
			name: 'invoiceDelivery.method',
			label: t('supplement.invoiceDelivery.method.label', { ns: 'data' }),
			options: electronicDeliveryMethods,
			required: true,
			getValue: (v) => (electronicDeliveryMethods && v.invoiceDelivery.method) || null,
			selectProps: {
				getOptionKey: (o) => o.id,
				getOptionLabel: (o) => o.displayName,
			},
		} as SelectField<SupplementVerifyFormFields, LookupValue>,
		// Email field, if delivery method is email
		...(formik.values.invoiceDelivery.method?.code === ElectronicDeliveryMethod.Email ? [EmailField] : []),
		// Fax field, if delivery method is fax
		...(formik.values.invoiceDelivery.method?.code === ElectronicDeliveryMethod.Fax ? [FaxField] : []),
		...(!noCredentials ? credentialDeliveryFields : []),
	];

	// Submit handler with error toast
	const handleSubmit = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: `irp/supplements/transfer_vehicle/verify`,
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	// Load
	useEffect(() => {
		if (!supplement?.key) return;

		setLoading(true);
		Promise.all([
			supplementsService.getElectronicDeliveryMethods().then(setElectronicDeliveryMethods),
			(() => {
				if (noCredentials) return Promise.resolve();
				return supplementsService.listCredentialDeliveryMethods(supplement.key).then(setCredentialDeliveryMethods);
			})(),
			carriersService.getContact(supplement.accountKey, Program.IRP).then(setPreferredContact),
		]).finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplement?.key, noCredentials]);

	// Supplement loaded, set initial values
	useEffect(() => {
		if (!supplement) return;

		formik.setValues({
			effectiveDate: supplement.effectiveDate || null,
			invoiceDelivery: {
				method: supplement.invoiceDelivery?.method || null,
				details: supplement.invoiceDelivery?.details || '',
			},
			physicalDelivery: {
				method: supplement.physicalDelivery?.method || null,
				details: supplement.physicalDelivery?.details || '',
				address: supplement.physicalDelivery?.address || {
					line1: '',
					line2: '',
					city: '',
					state: null,
					postalCode: '',
					country: '',
				},
			},
			invoiceComments: supplement.invoiceComments || '',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplement]);

	// Set invoice delivery details based on preferred contact
	useEffect(() => {
		const { method, details } = formik.values.invoiceDelivery;
		if (!method) return;

		// Method has not changed, do not overwrite
		if (method.id === supplement?.invoiceDelivery?.method?.id && details === supplement.invoiceDelivery.details) return;

		switch (method.code) {
			case ElectronicDeliveryMethod.Email:
				formik.setFieldValue('invoiceDelivery.details', preferredContact?.contact.email || '');
				break;
			case ElectronicDeliveryMethod.Fax:
				formik.setFieldValue('invoiceDelivery.details', phoneNumberFormat(preferredContact?.contact.fax || ''));
				break;
			default:
				// Clear invoice delivery details when method changes
				formik.setFieldValue('invoiceDelivery.details', '');
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.invoiceDelivery.method]);

	// Recalculate fees when effective date changes
	useEffect(() => {
		if (!supplement || !formik.values.effectiveDate) return;

		// Effective date not changed
		const original = dayjsFromObject(supplement.effectiveDate);
		const entered = dayjsFromObject(formik.values.effectiveDate);
		if (original && entered && original.isSame(entered)) return;

		// Save supplement
		supplementsService.update(supplement.key, { effectiveDate: formik.values.effectiveDate }).then(reloadSupplement);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.effectiveDate]);

	if (!supplement) return SupplementContentSkeleton;

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Box mb={4}>
						<Typography variant="h3" gutterBottom>
							{t('verify.title')}
						</Typography>
						<Typography>{t('verify.subtitle')}</Typography>
					</Box>

					<ClearFleetForm form={formik} fields={fields} loading={loading || formik.isSubmitting} />

					<CanAccess resource={Permissions.IRP.Supplements.Fields.InvoiceComments.resource} action={Actions.WRITE}>
						<Grid mt={3} container>
							<Grid item xs={12} sm={6}>
								<FormInputField
									label={t('invoice.comments.prompt', { ns: 'data' })}
									name="invoiceComments"
									slotProps={{
										input: {
											multiline: true,
											minRows: 2,
											disabled: loading || formik.isSubmitting,
											value: formik.values.invoiceComments,
											onChange: formik.handleChange,
										},
									}}
								/>
							</Grid>
						</Grid>
					</CanAccess>

					<Box mt={3}>
						<SupplementFeesDataGrid supplement={supplement} columnHeaders={feeGridHeaders} />
					</Box>
				</CardContent>
			</Card>

			<SupplementStepFooter
				nextLabel={t('buttons.next', { ns: 'core' })}
				onNext={handleSubmit}
				previousPath={previousPath.buildPath({ supplementKey })}
			/>
		</Box>
	);
}
